<template>
  <div>
    <!--<Create-->
    <!--:perfis="perfis"-->
    <!--:tipos="tiposMaped"-->
    <!--:create="createItem"-->
    <!--:close="closeCreate"-->
    <!--:descSingular="descSingular"-->
    <!--&gt;</Create>-->
    <Edit :item="currentItem" :update="updateItem" :close="closeEdit"></Edit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
    <CTab title="Ativos">
      <div class="filters" style="margin-top:0">
        <span class="filters__search">
          <input
            type="text"
            v-model="term"
            placeholder="Buscar por..."
            v-on:input="debounceLoadClientes()"
          />
          <img
            style="position: relative; right: -10px;"
            src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
          />
        </span>
      </div>
      <!-- clientes ativos -->
      <div>
        <div class="table-responsive">
          <datatable :columns="table.columns" :data="items" class="table table-hover">
            <template slot-scope="{ row }">
              <tr>
                <td>{{ row.id }}</td>
                <td>{{ row.razSoc }}</td>
                <td>{{ row.fant }}</td>
                <td>{{ row.cnpj }}</td>
                <td>{{ row.dtUltComp | date }}</td>
                <td>{{ row.dtValidacao | date }}</td>
                <td class="actions">
                  <button
                    type="button"
                    class="button button-primary"
                    v-on:click="openEdit(row)"
                  >
                    <fa-icon icon="pencil-alt" />
                  </button>
                  &nbsp;
                  <!--<button type="button" name="button" v-on:click="deleteConfirm(row)" class="button button-error"><fa-icon icon="trash-alt" /></button>-->
                </td>
              </tr>
            </template>
          </datatable>
        </div>
        <div style="display: flex; justify-content: center;">
          <pagination
                  :data="table.paginator"
                  :limit="5"
                  @pagination-change-page="loadClientes"
          ></pagination>
        </div>
      </div>
    </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import _debounce from 'lodash/debounce';
import { Validator } from 'simple-vue-validator';
import moment from 'moment';
import { get, post, put, del } from '../../helpers/apiRequest';
import Create from './Create';
import Edit from './Edit';

export default {
  name: 'clientes',
  components: {
    Create,
    Edit,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],
      term: '',
      currentItem: {},
      table: {
        filter: '',
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Razão Social', field: 'razSoc', sortable: false },
          { label: 'Nome Fantasia', field: 'fant', sortable: false },
          { label: 'CNPJ', field: 'cnpj', sortable: false },
          { label: 'Última Compra', field: 'dtUltComp', sortable: false },
          { label: 'Data Validação', field: 'dtValidacao', sortable: false },
          { label: '', field: '', sortable: false },
        ],
        paginator: {},
      },
    };
  },
  computed: {},
  methods: {
    loadDescs() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_CLIENTE'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadClientes(page = 1, take = 15) {
      get(`/admin/clientes?page=${page}&take=${take}&q=${this.term}`)
        .then((json) => {
          this.items = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    debounceLoadClientes: _debounce(function() {
      this.loadClientes();
    }, 300),
    openCreate() {
      this.$modal.show('mCreate');
    },
    closeCreate() {
      this.$modal.hide('cCreate');
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show('cEdit');
    },
    closeEdit() {
      this.$modal.hide('cEdit');
    },
    updateItem() {
      this.loading = true;
      this.currentItem.dtValidacao = moment(
        this.currentItem.dtValidacao
      ).format('YYYY-MM-DD');
      const request = put(
        `/admin/clientes/${this.currentItem.id}`,
        this.currentItem
      );
      request
        .then((json) => {
          this.loadClientes();
          this.closeEdit();
        })
        .catch(() => {
          this.loading = false;
        });
      return request;
    },
    moment,
  },
  beforeMount() {
    this.loading = true;
    this.loadClientes();
    this.loadDescs();
  },
};
</script>
