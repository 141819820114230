<template>
  <modal name="mCreate" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <div class="xrow">
          <ul class="tabs">
            <li class="tab-active">
              <a>{{`Criar ${descSingular}`}}</a>
            </li>
          </ul>
        </div>
        <div class="alert remover" v-show="!!errorMessage">
          <a href="#" class="close" @click="errorMessage = null">&times;</a>
          Falha ao cadastrar:<br>
          <p>{{ errorMessage }}</p>
        </div>
        <div class="form">
          <div class="xrow">
            <div class="col-2-3">
              <div class="form-group">
                <label for="">Nome</label>
                <input class="form-control" v-model="item.nome" />
                <div class="message">{{ validation.firstError('item.nome') }}</div>
              </div>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label for="">Ativo</label>
                <select class="form-control" v-model="item.ativo">
                  <option value="">-</option>
                  <option value="1">Sim</option>
                  <option value="0">Não</option>
                </select>
                <div class="message">{{ validation.firstError('item.ativo') }}</div>
              </div>
            </div>
          </div>
          <div class="xrow">
            <div class="col-1-3">
              <div class="form-group">
                <label for="">Documento</label>
                <TheMask mask="###.###.###-##" class="form-control" v-model="item.doc" />
                <div class="message">{{ validation.firstError('item.doc') }}</div>
              </div>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label for="">E-mail</label>
                <input class="form-control" v-model="item.email" />
                <div class="message">{{ validation.firstError('item.email') }}</div>
              </div>
            </div>
            <div class="col-1-3">
              <div class="form-group">
                <label for="">Telefone</label>
                <TheMask mask="(##) #########" class="form-control" v-model="item.tel" />
                <div class="message">{{ validation.firstError('item.tel') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="xrow">
          <div class="col-1-3">
            <div class="form-group">
              <label for="">Data Nascimento</label>
              <input class="form-control" v-model="item.dtNasc" type="date" />
              <div class="message">{{ validation.firstError('item.dtNasc') }}</div>
            </div>
          </div>
          <div class="col-1-3">
            <div class="form-group">
              <label for="">Senha</label>
              <input class="form-control" v-model="item.senha" type="password" />
              <div class="message">{{ validation.firstError('item.senha') }}</div>
            </div>
          </div>
        </div>
        <div class="xrow">
          <div class="col-1-3">
            <div class="form-group">
              <label for="">Tipo</label>
              <select class="form-control" v-model="item.tipo">
                <option value="">-</option>
                <option v-for="i in tipos" v-bind:value="i.id">{{ i.desc }}</option>
              </select>
              <div class="message">{{ validation.firstError('item.tipo') }}</div>
            </div>
          </div>
          <div class="col-1-3">
            <div class="form-group">
              <label for="">Perfil</label>
              <select class="form-control" v-model="item.perfil">
                <option value="">-</option>
                <option v-for="i in perfisPossiveis" v-bind:value="i.id">{{ i.descricao }}</option>
              </select>
              <div class="message">{{ validation.firstError('item.perfil') }}</div>
            </div>
          </div>
        </div>
        <div class="xrow">
          <div class="col-1-3" v-show="inputShouldBeVisible('matErp')">
            <div class="form-group">
              <label for="">Matrícula ERP</label>
              <input class="form-control" v-model="item.matErp" type="text" />
              <div class="message">{{ validation.firstError('item.matErp') }}</div>
            </div>
          </div>
          <div class="col-1-3" v-show="inputShouldBeVisible('codOp')">
            <div class="form-group">
              <label for="">ID de Operador</label>
              <input class="form-control" v-model="item.codOp" type="text" />
              <div class="message">{{ validation.firstError('item.codOp') }}</div>
            </div>
          </div>
          <div class="col-1-3" v-show="inputShouldBeVisible('codRca')">
            <div class="form-group">
              <label for="">ID de Representante</label>
              <input class="form-control" v-model="item.codRca" type="text" />
              <div class="message">{{ validation.firstError('item.codRca') }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit">
        Inserir
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import clone from "just-clone";
import { TheMask } from "vue-the-mask";
import { Validator } from "simple-vue-validator";
import _filter from "lodash/filter";
import _some from "lodash/some";
import CloseModal from "../../components/CloseModal";

import { isCpf } from "../../helpers/utils";

const initial = () => ({
  nome: "",
  ativo: "",
  doc: "",
  email: "",
  tel: "",
  perfil: "",
  senha: "",
  dtNasc: "",
  tipo: "",
  matErp: "",
  codOp: "",
  codRca: "",
});

export default {
  name: "mCreate",
  components: {
    TheMask,
    CloseModal,
  },
  props: ["create", "close", "perfis", "tipos", "descSingular"],
  data() {
    return {
      item: initial(),
      errorMessage: null,
    };
  },
  computed: {
    perfisPossiveis() {
      const perfis = _filter(this.perfis, (p) => {
        return _some(p.perfisTipos, (tipo) => tipo === this.item.tipo);
      });
      return perfis;
    },
  },
  validators: {
    "item.nome": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
    "item.doc": (value) =>
      Validator.custom(() => (isCpf(value) ? false : "CPF Inválido")),
    "item.email": (value) =>
      Validator.value(value)
        .required()
        .email(),
    "item.tel": (value) => Validator.value(value).required(),
    "item.perfil": (value) => Validator.value(value).required(),
    "item.dtNasc": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
    "item.matErp": {
      validator(value) {
        return Validator.custom(() =>
          this.extraFieldValidator("matErp", value)
        );
      },
    },
    "item.codOp": {
      validator(value) {
        return Validator.custom(() => this.extraFieldValidator("codOp", value));
      },
    },
    "item.codRca": {
      validator(value) {
        return Validator.custom(() =>
          this.extraFieldValidator("codRca", value)
        );
      },
    },
  },
  watch: {
    "item.tipo"(oldValue, newValue) {
      if (oldValue && newValue && oldValue !== newValue) {
        this.item.matErp = null;
        this.item.codOp = null;
        this.item.codRca = null;
      }
    },
  },
  methods: {
    extraFieldValidator(field, value) {
      if (
        field === "matErp" &&
        this.tipos[this.item.tipo].chave === "FUNCIONARIO"
      ) {
        return;
      }

      if (
        this.inputShouldBeVisible(field) &&
        (!value || (value && value.length <= 0))
      ) {
        return "Required";
      }
    },
    inputShouldBeVisible(inputName) {
      return (
        this.tipos[this.item.tipo] &&
        this.tipos[this.item.tipo].inputs.indexOf(inputName) >= 0
      );
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          const item = clone(this.item);
          this.create(item)
            .then(() => {
              this.item = initial();
            })
            .catch((error) => {
              this.errorMessage = error.message;
            });
        }
      });
    },
  },
};
</script>
