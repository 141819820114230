<template>
  <div class="row">
    <div v-for="(label, idx) in dictionary" class="col-6 hover">
      <div class="w-25 d-inline-block">
        <strong>
          <template v-if="!!label.label">
            {{ label.label }}
          </template>
          <template v-else>
            {{ label }}
          </template>
        </strong>
      </div>
      <div class="w-75 d-inline-block">
        <template v-if="!!label.type">
          <template v-if="label.type === 'monetary'">
            {{ data[idx] | monetary }}
          </template>
          <template v-else-if="label.type === 'boolean'">
            {{ data[idx] | boolean }}
          </template>
          <template v-else-if="label.type === 'date'">
            {{ data[idx] | date }}
          </template>
          <template v-else-if="label.type === 'datetime'">
            {{ data[idx] | datetime }}
          </template>
          <template v-else>
            {{ data[idx] }}
          </template>
        </template>
        <template v-else>
          {{ data[idx] }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JsonShowData",
  props: [
    "data",
    "dictionary"
  ]
}
</script>

<style scoped>

</style>