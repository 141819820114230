<template>
  <modal name="cEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Dados Cadastrais">
            <div class="form">
              <div class="alert remover" v-show="!!errorMessage">
                <a href="#" class="close" @click="errorMessage = null">&times;</a>
                Falha ao atualizar:<br>
                <p>{{ errorMessage }}</p>
              </div>
              <div>
                <JsonShowData
                  :data="item"
                  :dictionary="labels"
                />
              </div>
              <div class="row mt-4">
                <div class="col-12 pb-0">
                  <div class="form-group">
                    <h5>Colaboradores Vinculados:</h5>
                  </div>
                </div>
                <div class="col-12 table-responsive">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Telefone</th>
                      <th>Último Acesso</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="colaborador in item.colaboradores" v-bind:key="colaborador.id">
                      <td class="highlight">{{ colaborador.id }}</td>
                      <td class="highlight">{{ colaborador.nome }}</td>
                      <td class="highlight">{{ colaborador.email }}</td>
                      <td class="highlight">{{ colaborador.tel }}</td>
                      <td v-if="colaborador.ultimoLogAcesso[0]" class="highlight">{{ convertDataHora(colaborador.ultimoLogAcesso[0].dataacesso) }}</td>
                      <td v-else class="highlight"></td>
                    </tr>
                    <tr v-if="!item.colaboradores || (item.colaboradores && item.colaboradores.length === 0)">
                      <td colspan="5">
                        Nenhum colaborador vinculado!
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Data máxima para o cliente poder comprar no B2B (caso o parâmetro de bloquear por dias sem compra estiver ativo)</label>
                    <datetime
                        input-class="form-control"
                        v-model="item.dtValidacao"
                        type="date"
                        placeholder="Data de Validação"
                        :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                        value-zone="local"
                    />
                  </div>
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }" v-on:click="handleSubmit(false)">
        Alterar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import moment from "moment";
import CloseModal from "../../components/CloseModal";
import JsonShowData from "../../components/crud/JsonShowData";

export default {
  name: "cEdit",
  components: { CloseModal, JsonShowData },
  props: [
    "update",
    "close",
    "item"
  ],
  validators: {
    "item.dtValidacao": (value) => Validator.value(value).required(),
  },
  data() {
    return {
      filter: "",
      resultLimit: 100,
      activeTab: "dados",
      errorMessage: null,
      labels: {
        id: 'Cód',
        razSoc: "Razão social",
        fant: 'Fantasia',
        cnpj: 'CNPJ',
        inscEst: 'Inscr. est.',
        end: 'Rua',
        num: 'Núm.',
        comp: 'Compl.',
        bair: 'Bairro',
        cid: 'Cidade',
        uf: 'Estado',
        cep: 'CEP',
        nivConf: 'Nível confiança',
        prazMedMax: 'Prazo médio máx.',
        idPlPagPad: 'Cód. plano padrão',
        idCobPad: 'Cód. cob padrão',
        percTab: { label: 'Percentual tabela', type: 'percent' },
        intFv: { label: 'Integra FV', type: 'boolean' },
        intCc: { label: 'Integra Callcenter', type: 'boolean' },
        intB2B: { label: 'Integra B2B', type: 'boolean' },
        dtCad: { label: 'Dt. cad.', type: 'date' },
        dtUltComp: { label: 'Últ. compra', type: 'date' },
        atividade: 'Atividade',
        categoria: 'Categoria',
        rede: 'Rede',
        classeVenda: 'Classe',
        idRegiao: 'Cód. região',
        vendeForaCarteira: { label: 'Vende fora carteira', type: 'boolean' },
        acessaFlex: { label: 'Acessa Flex', type: 'boolean' },
        aplTaxaFixa: { label: 'Paga taxa fixa', type: 'boolean' },
        vlTotPedidosMes: { label: 'Valor pedido/mês', type: 'monetary' },
        vlMinB2B: { label: 'Vl mínimo B2B', type: 'monetary' },
        vlMinCC: { label:'Vl mínimo Callcenter', type: 'monetary' },
        vlMinFV: { label: 'Vl mínimo FV', type: 'monetary' },
      }
    };
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update().catch((error) => {
            this.errorMessage = error.message;
          });
        }
      });
    },
    moment,
    convertDataHora(dataHora = null) {    
        return moment(String(dataHora)).format('DD/MM/YYYY HH:mm')
    },
  },
};
</script>
